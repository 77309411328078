<template>
  <div class="administrators">
    <div class="administrators-left">
      <el-form :style="{ 'margin-top': '10px' }">
        <el-form-item>
          <el-input
            size="small"
            v-model.trim="searchForm.search"
            clearable
            placeholder="请输入关键词"
          ></el-input>
          <el-input
            size="small"
            v-model.trim="searchForm.username"
            clearable
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            class="el-form-btn"
            type="primary"
            size="small"
            @click="submitFun"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <div class="left-log">
        <logCom
          :title="'近10条查询记录'"
          height="calc(100% )"
          attr="search"
          attr2="username"
          :logList="logList"
          @selectLog="selectLog"
          ref="administratorsLogCom"
        />
      </div>
    </div>
    <div class="administrators-right" v-loading="loading">
      <el-table
        :data="list"
        :height="windowHeight - 160"
        style="width: 100%"
        header-row-class-name="tableheader-parent"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
      >
        <el-table-column label="用户名" prop="username"></el-table-column>
        <el-table-column label="账号ID" prop="id"></el-table-column>
        <el-table-column
          label="落查次数"
          prop="investigation_count"
        ></el-table-column>
        <el-table-column label="加入时间" prop="date_joined">
          <template slot-scope="scope">
            {{ scope.row.date_joined | capitalize }}
          </template>
        </el-table-column>
        <el-table-column label="最后登录时间" prop="last_login">
          <template slot-scope="scope">
            {{ scope.row.last_login | capitalize }}
          </template>
        </el-table-column>
        <el-table-column
          label="统一平台"
          prop="oauth_account"
          show-overflow-tooltip
          width="100"
        ></el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column
          label="次数使用情况"
          prop="name"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="viewDetail(scope.row)">
              查看
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="update(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page-size="pageSize"
        :currentPage="current"
        :sizesArr="[20, 50, 100, 200]"
        :pagerCount="5"
        background
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>
    <!-- 查看 次数使用情况-->
    <el-dialog title="次数使用情况" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-table
          :data="dialogTable"
          height="300"
          style="width: 100%"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column label="变更时间" prop="create_time" width="160">
            <template slot-scope="scope">
              {{ scope.row.create_time | capitalize }}
            </template>
          </el-table-column>
          <el-table-column label="变更类型" prop="reason"></el-table-column>
          <el-table-column
            label="次数变更"
            prop="change_value"
          ></el-table-column>
          <el-table-column label="备注" prop="remark"></el-table-column>
        </el-table>
        <pagination
          :total="dialogTotal"
          :page-size="dialogPageSize"
          :currentPage="dialogCurrent"
          :sizesArr="[20, 50, 100]"
          :pagerCount="5"
          layout="total,  pager"
          :small="true"
          :background="true"
          @currentChange="dialogCurrentChange"
          @sizeChange="dialogSizeChange"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 编辑  弹框-->
    <el-dialog title="编辑" :visible.sync="updateVisible" width="30%">
      <div>
        <el-form
          :model="updateForm"
          label-width="100px"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="次数余额:" prop="change_value">
            <el-input
              size="small"
              style="width:calc(100% - 130px)"
              v-model.number="updateForm.change_value"
              placeholder="输入次数"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            {{ updateForm.remark }}
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="updateVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="updateSure">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userList, singleUser, investigationCountChange } from '@/api/user.js'
import logCom from '../components/LogCom.vue'
export default {
  data() {
    return {
      loading: false,
      searchForm: {
        search: '',
        username: ''
      },
      logList: [], //记录
      list: [],
      current: 1,
      pageSize: 20,
      total: 0,
      //   弹框
      dialogVisible: false,
      dialogTable: [],
      dialogTotal: 0,
      dialogPageSize: 20,
      dialogCurrent: 1,
      viewObj: {},
      //  编辑弹框
      updateVisible: false,
      updateForm: { change_value: '', remark: '' },
      rules: {
        change_value: [
          { required: true, message: '次数不能为空' },
          { type: 'number', message: '次数余额必须为数字值' }
        ]
      }
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return ''
      let arr = value.split('+')
      if (Array.isArray(arr)) {
        return arr[0]
      } else {
        return value
      }
    }
  },
  components: {
    logCom
  },
  computed: {
    windowHeight() {
      return this.$store.state.windowData.height
    }
  },

  created() {
    this.getUserList()
    this.getLocalStorageFun()
  },
  methods: {
    //   查看  次数使用情况
    viewDetail(row) {
      this.viewObj = Object.assign({}, row)
      this.dialogCurrent = 1
      let obj = {
        user: row.id,
        page: this.dialogCurrent,
        page_size: this.dialogPageSize
      }
      singleUser(obj).then(res => {
        this.dialogTotal = res.data.count
        this.dialogTable = res.data.data
      })
      this.dialogVisible = true
    },
    // 弹框 分页
    dialogCurrentChange(val) {
      this.dialogCurrent = val
      let obj = {
        user: this.viewObj.id,
        page: this.dialogCurrent,
        page_size: this.dialogPageSize
      }
      singleUser(obj).then(res => {
        this.dialogTotal = res.data.count
        this.dialogTable = res.data.data
      })
    },
    dialogSizeChange(val) {
      this.dialogCurrent = 1
      this.dialogPageSize = val
      let obj = {
        user: this.viewObj.id,
        page: this.dialogCurrent,
        page_size: this.dialogPageSize
      }
      singleUser(obj).then(res => {
        this.dialogTotal = res.data.count
        this.dialogTable = res.data.data
      })
    },
    // 编辑
    update(row) {
      this.updateVisible = true
      this.updateForm.id = row.id
      this.updateForm.change_value = ''
      this.updateForm.remark = row.remark
    },
    updateSure() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          investigationCountChange(
            this.updateForm.id,
            this.updateForm.change_value
          ).then(res => {
            if (res.status == 200) {
              this.$message({
                type: 'success',
                message: '变更成功！'
              })
              this.updateVisible = false
              this.getUserList()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取数据
    getUserList() {
      this.loading = true
      return new Promise((resolve, reject) => {
        let obj = {
          search: this.searchForm.search,
          username: this.searchForm.username,
          page: this.current,
          page_size: this.pageSize
        }
        userList(obj).then(res => {
          if (res) {
            this.list = res.data.data
            this.total = res.data.count
            resolve(res)
          }
          this.loading = false
        })
      })
    },
    selectTabFun(row, i) {
      this.selectTabObj = Object.assign({}, row)
    },
    // 提交任务
    submitFun() {
      this.$refs.administratorsLogCom.selectIndex = null
      this.localStorageFun()
      this.getUserList().then(res => {
        this.getLocalStorageFun()
      })
    },
    // 记录
    selectLog(row) {
      this.current = 1
      let obj = {
        search: row.search,
        username: row.username,
        page: this.current,
        page_size: this.pageSize
      }
      userList(obj).then(res => {
        if (res) {
          this.list = res.data.data
          this.total = res.data.count
        }
      })
    },
    // 分页
    currentChange(val) {
      this.current = val
      this.getUserList()
    },
    sizeChange(val) {
      this.current = 1
      this.pageSize = val
      this.getUserList()
    },
    // 查询记录
    localStorageFun() {
      let arrString = localStorage.getItem('facebookuserSearch')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        if (arr.length < 10) {
          if (this.isEmpty(this.searchForm)) {
            arr.unshift(this.searchForm)
          }
        } else {
          if (this.isEmpty(this.searchForm)) {
            arr.unshift(this.searchForm)
          }
        }
      } else {
        arr = []
        if (this.isEmpty(this.searchForm)) {
          arr.unshift(this.searchForm)
        }
      }
      localStorage.setItem('facebookuserSearch', JSON.stringify(arr))
    },
    getLocalStorageFun() {
      let arrString = localStorage.getItem('facebookuserSearch')
      let arr = JSON.parse(arrString)
      if (arr && Array.isArray(arr)) {
        this.logList = [].concat(arr)
      }
    },
    isEmpty(obj) {
      let arr = []
      for (let i in obj) {
        if (obj[i] && obj[i].trim() !== '') {
          arr.push(obj[i])
        }
      }
      if (arr.length == 0) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss">
.administrators {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .administrators-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-btn {
      width: 100%;
      border: 0px solid #000000;
      border-radius: 16px;
    }

    .left-log {
      width: 100%;
      height: calc(100% - 150px);
      overflow: hidden;
      margin-top: 20px;
      position: relative;
    }
  }
  .administrators-right {
    width: calc(100% - 270px);
    height: 100%;
    overflow: hidden;
    background: white;
    padding: 10px;
    .el-table--fit {
      border-bottom: 1px solid #e5e5e5;
    }
  }
}
</style>
